<template>
  <div class="modal">
    <div class="modal-content">
      <span @click="$emit('close')" class="close">&times;</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal'
}
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
}

/* Modal Content/Box */
.modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  width: 40%;
  border: 1px solid #efefef;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .modal-content {
    width: 80%;
  }
}
</style>
