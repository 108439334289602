<template>
  <div id="app">

    <div>

      <div v-if="!results">

        <h1 style="text-transform: uppercase">Standstill Calculator</h1>

        <p>Clear and timely communication is essential to the procurement process.</p>

        <p>The Herbots Standstill Calculator is a free tool
          that subject to its <span class="underline" @click="showModal = true">terms</span> lets you calculate essential dates around which to
          plan communication with preferred bidders and unsuccessful tenderers.</p>

        <p>Enter the Notice Date below and the calculator will calculate and display the expiry of Standstill and of the 30-day challenge period in Ireland.</p>
      </div>

      <div v-if="!results" style="display: block; margin: 60px auto;">
          <h3 style="text-align: center;">Notice Date</h3>
          <datepicker style="display: block; margin: 0 auto;" :inline="true" placeholder="Please select date"	@selected="updateDatePicker"></datepicker>

      </div>

      <modal v-if="results" @close="results = false" style="margin-top: 250px;">

        <div style="display: block; margin-top: 50px;">

          <div class="row" style="margin-top: 20px;">

            <div class="column">
              <label>Standstill <span class="italic">(if e-Notice)</span></label>
            </div>

            <div class="column">
              <div class="block">{{ eNotice.date  }}. <span v-if="eNotice.message">{{ eNotice.message }} {{ eNotice.expectedDate }}.</span></div>
            </div>

          </div>

          <div class="row">

            <div class="column">
              <label>Standstill <span class="italic">(if no e-Notice)</span></label>
            </div>

            <div class="column">
              {{  notENotice.date }}. <span v-if="notENotice.message">{{ notENotice.message }} {{ notENotice.expectedDate }}.</span>
            </div>

          </div>

          <div class="row">

            <div class="column">
              <label>30-day challenging period</label>
            </div>

            <div class="column">
              {{ challengingPeriod.date }}. <span v-if="challengingPeriod.message">{{ challengingPeriod.message }} {{ challengingPeriod.expectedDate }}.</span>
            </div>

          </div>

          <div style="margin-top: 40px;">
            <button class="button" @click="startOver">Start Over</button>
          </div>

        </div>

      </modal>

    </div>

    <div v-if="!results" style="margin-top: 20px; border-top: 1px solid #efefef;">
      <p>For further information, contact <a href="https://herbots.ie/contact/">Bruno Herbots</a> or any member of our EU procurement group at
        <a href="mailto:standstill@herbots.ie">standstill@herbots.ie</a></p>
    </div>


    <modal v-if="showModal" @close="showModal = false">
        <h2>Terms</h2>
        <p>Whilst every effort has been made to ensure that the calculation of the applicable standstill and 30-day challenge period
          accurately reflect the position under the
          <a target="_blank" href="http://www.irishstatutebook.ie/eli/2010/si/130/made/en/print">Irish Public Contracts Remedies Regulations</a>,
          the
          <a target="_blank" href="http://www.irishstatutebook.ie/eli/2010/si/131/made/en/print">Utilities Remedies Regulations</a>
          and the
          <a target="_blank" href="http://www.irishstatutebook.ie/eli/2017/si/326/made/en/print">Concession Remedies Regulations</a>,
          you agree, by using this app, that Herbots accepts no liability in contract, tort or otherwise for any loss or damage caused by or arising directly or
          indirectly in connection with any reliance or use of this free app.</p>
    </modal>

  </div>
</template>

<script>
import Modal from './components/Modal';
import Datepicker from 'vuejs-datepicker';

import moment from 'moment'

export default {
  name: 'App',
  data() {
    return {
      showModal: false,
      noticeDate: new Date(),
      eNotice: {
        date: false,
        message: false,
        expectedDate: false
      },
      notENotice: {
        date: false,
        message: false,
        expectedDate: false
      },
      challengingPeriod: {
        date: false,
        message: false,
        expectedDate: false
      },
      results: false,
      publicHolidaysInIreland: [
        {
          day: "New Year's Day",
          date: '1 January'
        },
        {
          day: "St. Patrick's Day",
          date: '17 March'
        },
        {
          day: "St. Patrick's Day",
          date: '17 March'
        },
        {
          day: "St Bridgid day",
          date: '18 March'
        },
        {
          day: "Easter Monday",
          date: '5 April'
        },
        {
          day: "Early May Bank Holiday",
          date: '3 May'
        },
        {
          day: "June Bank Holiday",
          date: '7 June'
        },
        {
          day: "August Bank Holiday",
          date: '2 August'
        },
        {
          day: "October Bank Holiday",
          date: '25 October'
        },
        {
          day: "Christmas Day",
          date: '25 December'
        },
        {
          day: "St. Stephen's Day",
          date: '26 December'
        },
      ]
    }
  },
  components: {
    Modal,
    Datepicker
  },
  computed: {

  },
  methods: {
    updateDatePicker(value) {

      this.noticeDate = value

      this.resetDates()
      this.calculateDate('eNotice', 14)
      this.calculateDate('notENotice', 16)
      this.calculateDate('challengingPeriod', 30)

      this.results = true
    },
    startOver(){
      this.results = false
    },
    resetDates(){
      this.eNotice = {
        date: false,
        message: false,
        expectedDate: false
      }
      this.notENotice = {
        date: false,
        message: false,
        expectedDate: false
      }
      this.challengingPeriod = {
        date: false,
        message: false,
        expectedDate: false
      }
    },
    calculateDate(type, days){
      let hasBeenExtended = false
      let expectedDate = false
      // e-Notice
      // eslint-disable-next-line no-undef
      const getCalculatedDate = moment(this.noticeDate, "DD-MM-YYYY").add(days, 'days')
      this[type].date = getCalculatedDate.format('D MMMM YYYY');

      // Is this a Saturday?
      if(this.isDateOnSaturday(getCalculatedDate)){
        hasBeenExtended = true
        expectedDate = getCalculatedDate.add(2, 'days')
        this[type].message = "As this date is a Saturday, extend to"
        this[type].expectedDate = expectedDate.format('D MMMM YYYY')
      }

      // Is this a Sunday?
      if(this.isDateOnSunday(getCalculatedDate)){
        hasBeenExtended = true
        expectedDate = getCalculatedDate.add(1, 'days')
        this[type].message = "As this date is a Sunday, extend to"
        this[type].expectedDate = expectedDate.format('D MMMM YYYY')
      }

      // Is this a public holiday?
      if(this.isDateOnPublicHoliday(getCalculatedDate)){

        expectedDate = getCalculatedDate.add(1, 'days')

        this[type].message = "As this date is a public holiday in Ireland, extended to"

        if(hasBeenExtended){
          this[type].message = "As this is a bank holiday weekend in Ireland, extend to"
        }

        this[type].expectedDate = expectedDate.format('D MMMM YYYY')

      }

    },
    isDateOnPublicHoliday(getCalculatedDate){

      // eslint-disable-next-line no-unused-vars
      let bankHolidayDates = this.publicHolidaysInIreland.filter(function (e) {

        if( getCalculatedDate.format('D MMMM') == e.date){
            return true
        }

      });

      if(bankHolidayDates.length > 0){
        return true
      }

      return false
    },
    isDateOnSaturday(getCalculatedDate){

      if( getCalculatedDate.format('dddd') == 'Saturday'){
        return true
      }

      return false

    },
    isDateOnSunday(getCalculatedDate){

      if( getCalculatedDate.format('dddd') == 'Sunday'){
        return true
      }

      return false

    }
  }
}
</script>

<style>
body{
  padding: 30px;
  max-width: 640px;
  margin: 0 auto;
  min-width: 300px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 auto;
  font-size: 16px;
}

.underline{
  text-decoration: underline;
  color: #E84926;
  cursor: pointer;
}

h1{
  margin-bottom: 40px;
}

p{
  line-height: 26px;
}

label{
  font-weight: bold;
}

.italic{
  font-style: italic;
}

.row{
  display: flex;
  margin-bottom: 10px;
}
.column{
  display: inline-block;
  width: 50%;
}

button{
  border: none;
  color: white;
  background: #E84926;
  padding: 8px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

a, a:visited{
  color: #E84926;
}

.vdp-datepicker__calendar{
  position: relative !important;
  margin: 0 auto;
  max-width: 260px;
}

@media only screen and (max-width: 640px) {
  h1{
    font-size: 22px;
    margin: 0;
  }
  .row{
    display: block;
    margin-bottom: 20px;
  }
  .column{
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
